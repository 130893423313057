<!--
 * @Author: your name
 * @Date: 2020-11-26 20:27:18
 * @LastEditTime: 2021-05-13 11:06:18
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \sd-vue-admin\src\views\project\treasury\requisition\documents\components\AddGoods.vue
-->
<template>
  <div>
    <el-dialog
      v-loading.fullscreen.lock="fullscreenLoading"
      :modal="false"
      :close-on-click-modal="false"
      :title="'调拨单号：' + form.bill_no"
      :visible.sync="dialogVisible"
      width="1100px"
      center
    >
      <div class="rods">
        <div
          v-show="form.allot_status_text == '已作废'"
          style="display: flex; justify-content: center; align-items: center"
        >
          <h3>
            <span>作废人：</span>
            {{ form.scrap_name }}
            <span>作废时间：</span>
            {{ form.scrap_time }}
          </h3>
        </div>

        <el-form
          ref="form"
          v-model="form"
          :inline="true"
          class="demo-form-inline"
          label-width="150px"
        >
          <el-form-item label="出货仓库：">
            <el-select
              v-model="form.out_id"
              :disabled="type == 1"
              placeholder="出货仓库"
              style="width: 130px"
            >
              <el-option
                v-for="list in cklist"
                :key="list.id"
                :label="list.depot_name"
                :value="list.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="申请时间：">
            <span>{{ form.add_time }}</span>
          </el-form-item>
          <el-form-item label="申请人：">
            <span>{{ form.add_name }}</span>
          </el-form-item>
          <el-form-item v-if="form.allot_status != 1" label="审核时间：">
            <span>{{ form.check_time }}</span>
          </el-form-item>
          <el-form-item label="入货仓库：">
            <el-select
              v-model="form.in_id"
              :disabled="type == 1"
              placeholder="入货仓库"
              style="width: 130px"
            >
              <el-option
                v-for="item in cklist"
                :key="item.id"
                :label="item.depot_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="调拨类型：">
            <span>{{ form.allot_type_text }}</span>
          </el-form-item>
          <el-form-item label="审核人：">
            <span>{{ form.check_name }}</span>
          </el-form-item>
        </el-form>
        <!-- show-summary -->
        <el-table
          stripe
          :data="form.goods_data"
          border
          style="width: 100%"
          show-summary
          :summary-method="getSummaries"
        >
          <el-table-column
            type="index"
            :index="indexMethod"
            width="50"
            align="center"
          ></el-table-column>
          <!-- 商品类型 -->
          <el-table-column
            label="类型"
            width="60"
            prop="goods_type"
            align="center"
          >
            <template #default="{ row }">
              {{
                row.goods_type == 1 ? '常品' : row.goods_type == 2 ? '赠品' : ''
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="商品名称"
            align="center"
            prop="goods_name_print"
            width="250"
          ></el-table-column>
          <el-table-column
            label="商品规格"
            align="center"
            prop="goods_spec"
            width="150"
          ></el-table-column>
          <el-table-column
            label="单位"
            align="center"
            prop="goods_unit"
            width="110"
          ></el-table-column>
          <el-table-column
            label="申请数量"
            align="center"
            prop="apply_goods_num"
            width="80"
          >
            <template slot-scope="scope">
              <el-input v-model="scope.row.apply_goods_num" disabled></el-input>
            </template>
          </el-table-column>
          <el-table-column
            v-if="
            (type == 1 && form.allot_status_text == '已对账') ||
              (type == 1 && form.allot_status_text == '已审核') ||
              (type != 1 && form.allot_status_text == '待审核')
            "
            label="实际数量"
            align="center"
            prop="goods_num"
            width="100"
          >
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.goods_num"
                :disabled="numNoedit"
                @input="moneyN(scope.row)"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="单价"
            align="center"
            prop="goods_price"
            width="100"
          >
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.goods_price"
                :disabled="form.allot_status != 1"
                @input="moneyN(scope.row)"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="金额"
            align="center"
            prop="total_price"
            width="100"
          ></el-table-column>
          <!-- <el-table-column
            label="备注"
            align="center"
            prop="remark"
            width="150"
          ></el-table-column> -->
        </el-table>
        <el-row class="beizhu">
          <span>备注：</span>
          <el-input
            v-model="form.remark"
            type="textarea"
            :rows="2"
            placeholder="备注"
            style="width: 220px"
          ></el-input>
        </el-row>
        <div v-if="form.old_code">
          <span>修改记录:</span>
          <el-table
            stripe
            :data="changeOld"
            border
            style="width: 70%; margin-bottom: 10px"
            height="100"
          >
            <el-table-column label="原单号" align="center" prop="old_code">
              <template #default="{ row }">
                <span class="underline" @click="handleCheckDiaoboOrder(row)">
                  {{ row.old_code }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              label="修改时间"
              align="center"
              prop="add_time"
            ></el-table-column>
            <el-table-column
              label="修改人"
              align="center"
              prop="update_name"
            ></el-table-column>
          </el-table>
        </div>
        <div>
          <span>相关订单:</span>
          <el-table
            stripe
            :data="form.orders"
            border
            style="width: 70%"
            height="200"
          >
            <el-table-column
              v-for="trim in ordersd"
              :key="trim.order_id"
              :label="trim.label"
              :align="trim.center"
              :prop="trim.prop"
              :width="trim.width"
            >
              <template #default="{ row }">
                <div v-if="trim.label == '单号'">
                  <span class="underline" @click="handleCheckOrder(row)">
                    {{ row[trim.prop] }}
                  </span>
                </div>
                <div v-else>
                  {{ row[trim.prop] }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="flexe">
          <span>{{ form.allot_status_text }}</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          v-show="form.allot_status == 1"
          v-if="isEdit && !showOld && type == 2"
          type="primary"
          @click="shenhe"
        >
          审 核
        </el-button>

        <el-button
          v-if="isEdit && type == 2"
          v-show="form.allot_status == 1 && !showOld && cancel == 1"
          @click="zuofei"
        >
          作 废
        </el-button>
        <el-button v-if="showOld" type="primary" @click="handleBack">
          返回
        </el-button>
        <el-button @click="handlePrint">打 印</el-button>
        <el-button
          v-if="form.allot_status_text == '待支付' && cancel == 1"
          @click="handleDelete"
        >
          作 废
        </el-button>
        <el-button @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <!--    打印预览组件-->
    <ruilang-display-dialog
      ref="RuilangDisplayDialog"
      bill_type="DB"
      :data_id="form.id"
      :type="1"
    ></ruilang-display-dialog>
    <advance-order ref="advanceOrder"></advance-order>
    <sale-order-detail ref="saleOrder" />
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  import advanceOrder from '@/views/project/sale/bills/advanceOrder/components/checkOrder/index.vue'
  // import SaleOrder from '@/views/project/sale/bills/saleOrder/components/checkOrder/index.vue'
  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'

  export default {
    name: 'DiaoboOrder',
    components: {
      RuilangDisplayDialog,
      advanceOrder,
      SaleOrderDetail: () =>
        import(
          '@/views/project/sale/bills/saleOrder/components/checkOrder/index.vue'
        ),
    },
    props: {
      isEdit: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        cancel: 0,
        showOld: false,
        dialogVisible: false,
        changeOld: [],
        type: 1, //1查看2审核
        form: {
          bill_no: '',
          out_id: '',
          out_name: '',
          in_id: '',
          id_name: '',
          add_time: '',
          check_time: '',
          allot_type: '',
          allot_type_text: '',
          allot_status_text: '',
          remark: '',
          add_name: '',
          check_name: '',
        },
        cklist: [],
        url: {
          lists: '/depotAdmin/bill-allot/info',
          warehouse: '/baseAdmin/common/depot-list',
          fei: '/depotAdmin/bill-allot/cancel',
          shenh: '/depotAdmin/bill-allot/check',
        },
        fullscreenLoading: false,
        ordersd: [
          {
            label: '单号',
            align: 'center',
            prop: 'bill_code',
            width: '',
          },
          {
            label: '客户',
            align: 'center',
            prop: 'cust_name',
            width: '',
          },
          {
            label: '老板',
            align: 'center',
            prop: 'boss',
            width: '',
          },
          {
            label: '电话',
            align: 'center',
            prop: 'mobile',
            width: '',
          },
          {
            label: '备注',
            align: 'remark',
            prop: 'remark',
            width: '',
          },
        ],
        id: '',
      }
    },
    computed: {
      numNoedit() {
        // 不可编辑
        // scope.row.allot_status !== 1 &&
        //         scope.row.order_codes !== '' &&
        //         scope.row.allot_type !== 1
        if (this.form.is_wxpay == 1 && this.form.pay_status == 1) {
          return true
        } else {
          return false
        }
      },
    },
    watch: {
      dialogVisible(val) {
        if (!val) {
          this.$emit('getlist')
          this.showOld = false
          this.changeOld = []
        }
      },
    },
    mounted() {
      this.handlerck()
    },
    methods: {
      // 计算金额数
      moneyN(item) {
        let num = 0
        if (Number(item.goods_num) && Number(item.goods_price)) {
          num = Number(item.goods_num) * Number(item.goods_price)
        }
        if (item.goods_type == 1) {
          console.log('set', num)
          this.$set(item, 'total_price', num.toFixed(2))
          item.total_price = num.toFixed(2)
        } else {
          this.$set(item, 'total_price', 0)
          item.total_price = 0
        }
      },
      getSummaries(param) {
        console.log('asd')
        // const { columns, data } = param
        // const sums = []
        // columns.forEach((column, index) => {
        //   if (index === 0) {
        //     sums[index] = '总价'
        //     return
        //   } else if (index == 3 || index == 4 || index == 5) {
        //     const values = data.map((item) => Number(item[column.property]))
        //     if (!values.every((value) => isNaN(value))) {
        //       sums[index] = values.reduce((prev, curr) => {
        //         const value = Number(curr)
        //         if (!isNaN(value)) {
        //           return prev + curr
        //         } else {
        //           return prev
        //         }
        //       }, 0)
        //       sums[index]
        //     } else {
        //       sums[index] = ''
        //     }
        //   }
        // })
        // return sums
        const { columns, data } = param
        // 需要合计的下标
        let i = [8]
        let sums = []
        // let val = data.map((item) => {
        //   console.log(item)
        // })
        columns.forEach((col, idx) => {
          i.forEach((n) => {
            if (idx == n) {
              console.log(col.property)
              const val = data.map((item) => Number(item[col.property]))
              console.log(val)
              if (!val.every((value) => isNaN(value))) {
                const a = val.reduce((total, num) => {
                  if (!isNaN(total)) {
                    return total + num
                  }
                })
                sums[n] = a.toFixed(2) + ' 元'
              }
            }
          })
          if (idx == 0) {
            sums[idx] = '合计'
          }
        })
        console.log('sss', sums)
        return sums
      },
      shenhe() {
        var goods = []
        this.form.goods_data.forEach((item) => {
          var b = {
            id: item.id,
            goodsId: item.goods_id,
            goodsName: item.goods_name,
            goodsNum: item.goods_num,
            goodsPrice: item.goods_price,
            goodsSpec: item.goods_spec,
            goodsUnit: item.goods_unit,
            unitID: item.unit_id,
            goodsChildId: item.goods_child_id,
            goodsDate: '',
          }
          goods.push(b)
        })
        postAction(this.url.shenh, {
          allot_id: this.form.id,
          out_id: this.form.out_id,
          in_id: this.form.in_id,
          goods: JSON.stringify(goods),
          remark: this.form.remark,
        })
          .then((res) => {
            console.log(res, '审核成功')
            if (res.code == 200) {
              setTimeout(() => {
                this.$message({
                  message: res.msg,
                  type: 'success',
                })
                this.dialogVisible = false
                this.$emit('getlist')
              }, 800)
            }
          })
          .catch((err) => {
            console.log(err, '审核失败')
          })
      },
      zuofei() {
        postAction(this.url.fei, {
          allot_id: this.form.id,
        })
          .then((res) => {
            console.log(res, '作废成功')
            if (res.code == 200) {
              this.$message({
                message: '作废成功',
                type: 'warning',
              })
              this.dialogVisible = false
              this.$emit('getlist')
            }
          })
          .catch((err) => {})
      },
      handlerck() {
        this.fullscreenLoading = true
        postAction(this.url.warehouse, {})
          .then((res) => {
            console.log(res, '仓库')
            this.cklist = res.data
            this.fullscreenLoading = false
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      indexMethod(index) {
        return index + 1
      },

      handlerlist(row) {
        console.log(row, '详情')
        postAction(this.url.lists, { allot_id: row.id })
          .then((res) => {
            console.log(res, '详情成功')
            this.changeOld = []
            if (!this.showOld) this.id = res.data.id
            this.form = res.data
            this.changeOld.push({
              old_code: this.form.old_code,
              add_time: this.form.add_time,
              add_name: this.form.add_name,
              update_name: this.form.update_name,
              old_id: this.form.old_id,
            })
            if (this.form.allot_status == 1 || this.form.allot_status == 0) {
              this.form.goods_data.forEach((list) => {
                if (list.goods_num == 0) {
                  list.goods_num = JSON.parse(
                    JSON.stringify(list.apply_goods_num)
                  )
                }
              })
            }
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then((_) => {
            done()
          })
          .catch((_) => {})
      },
      handlePrint() {
        this.$refs.RuilangDisplayDialog.dialogFormVisible = true
      },
      // 查看订单详情
      handleCheckOrder(row) {
        console.log(row)
        if (row.bill_code.indexOf('XS') > -1) {
          console.log('xs')
          // 销售单查看
          this.$refs['saleOrder'].isshowDialog = true
          this.$refs['saleOrder'].id = Number(row.order_id)
        } else {
          // 预订单查看
          this.$refs['advanceOrder'].showDialog()
          this.$refs['advanceOrder'].orderStatus2 = row.bill_status_text
          this.$refs['advanceOrder'].id = Number(row.order_id)
        }
      },
      handleCheckDiaoboOrder(row) {
        console.log(row)
        row.id = row.old_id
        this.handlerlist(row)
        this.showOld = !this.showOld
        // this.$refs.diaoboOrder.dialogVisible = true
        // this.$refs.diaoboOrder.type = 1
        // this.$refs.diaoboOrder.handlerlist(row)
      },
      handleBack() {
        let row = { id: this.id }
        this.showOld = !this.showOld
        this.handlerlist(row)
      },
      handleDelete() {
        this.$confirm('确定作废此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            postAction('/depotAdmin/bill-allot/cancel', {
              allot_id: this.id,
            }).then((r) => {
              this.$message.success('作废成功')
              this.dialogVisible = false
              this.$emit('getlist')
            })
          })
          .catch(() => {
            console.log('no')
          })
      },
    },
  }
</script>

<style>
  .beizhu {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 0px;
  }
  .flexe {
    position: absolute;
    top: 0;
    right: 10%;
    padding: 30px 20px;
    font-weight: 600;
    color: chocolate;
    background-color: rgba(236, 225, 212);
    border: 1px chocolate solid;
    border-radius: 50%;
    opacity: 0.5;
  }
  .rods {
    position: relative;
  }
</style>
